a:not(.btn) {
    font-weight: bold;
    &:visited {
        color: $ac-link-color-visited;
    }

    &.alert-link:visited{
        color: inherit;
    }
}

