.fade.show {
    opacity: 1;
}

.collapse.show {
    display: block;
}

tr.collapse.show {
    display: table-row;
}

tbody.collapse.show {
    display: table-row-group;
}

.navbar-collapse.show {
    overflow-y: auto;
}

@media (min-width: 768px) {
    .navbar-collapse.show {
        overflow-y: visible;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-backdrop.show {
    opacity: 0.25;
    filter: alpha(opacity=25);
}

.tooltip.show {
    opacity: 0.9;
    filter: alpha(opacity=90);
}
