html
body {
    //min-height: 75rem;
    padding-top: 7.5rem;
    @include media-breakpoint-up(md) {
        padding-top: 6.5rem;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 6.5rem;
    }

}

main {
    padding-bottom: 15px;
}
pre {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
}

accordion-group {
    .card {
        margin-bottom: 0;
    }
}

.card {
    margin-bottom: 22px;
    >.card-footer {
        background-color: #fff;
    }

    &.card-main {
        border: 0;
        @include rounded(0);
        @include box-shadow(2px,2px,2px,0, rgba(0, 0, 0, 0.35));


        &.card-default {
            >.card-header {
                background-color: #fff;
                > .card-title {
                    font-size: 28px;
                }

                h1,h2,h3,h4,h5 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }

        > .card-header {
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            &.card-header-lg {
                color: white;
                text-align: center;
                > h3 {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    @media (max-width: $screen-md-bottom) {
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
            &.card-header-md {
                > h3 {
                    margin-top: 15px;
                    margin-bottom: 15px;
                    @media (max-width: $screen-md-bottom) {
                        margin-top: 3px;
                        margin-bottom: 3px;
                    }
                }
            }
            &.card-header-sm {
                > h4 {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    @media (max-width: $screen-md-bottom) {
                        margin-top: 3px;
                        margin-bottom: 3px;
                    }
                }
            }
            &.bg-primary {
                color: white;
            }
        }

        > .card-body {
            h1, h2, h3, h4, h5, h6 {
                margin-top: 0;
            }
        }

        table:not(.table-hover) {
            width: 100%;
            &.table {
                margin-bottom: 0;
                > tbody > tr:first-of-type > td {
                    border-top-width: 0;
                }
            }
        }
    }
}

.toast-top-right {
    top: $header-height + 47px;
}

.sub-navbar-visible {
    .toast-top-right {
        top: $header-height + $sub-navbar-height + 47px;
    }
}

.btn-acceo-group {
    button {
        margin: 0.25rem;
    }
}

.form-group.has-error .help-block, .question-form-group.has-error .help-block {
    display: block;
    margin-bottom: initial;
    font-size: small;
}

.has-error {
    input {
        border-color: #D9534F !important;
    }
}

.table-hover {
    cursor: pointer;
}

.table-base {
    display: table;
    width: 100%;

    &.table-inner-border {
        .table-row:first-of-type {
            .table-cell {
                border-top: 0;
            }
        }

        .table-row {
            .table-cell {
                padding: 8px;
                border-top: 1px solid #ddd;
            }
        }
    }

    .table-row {
        display: table-row;

        .table-cell {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.table {
    &.table-hover  {
        > tbody {
            > tr:not(.detail-row) {
                > td.active:hover,
                > th.active:hover,
                &.active:hover > td,
                &:hover > .active,
                &:hover,
                &.active:hover > th {
                    background-color: lighten($ac-color-primary-blue, 45%);
                }
            }
        }
        .detail-row {
            background-color: #fff!important;
            > td {
                &:hover {
                    background-color: #fff;
                }
            }
        }
    }
    > thead, > tbody, > tfoot {
        tr {
            > td.active,
            > th.active,
            &.active > td,
            &.active > th {
                background-color: lighten($ac-color-primary-blue, 45%);
            }
        }
    }

    .detail-row {
        td {
            .detail-block {
                padding: 10px 0 10px 20px;
            }
        }
    }
}

li {
    a:not(.btn):visited {
        color: $ac-color-primary-blue;
    }

    &.active {
        a:visited {
            color: #fff;
        }
    }
}

label {
    font-weight: normal;
}

@media (min-width: 768px) {
    .form-horizontal .col-form-label {
        text-align: left;
    }
}

.btn-acceo-adjust {
    padding-right: 12px;
    padding-left: 12px;

    &.btn-secondary {
        min-width: inherit !important;
    }
    &.btn-primary {
        min-width: inherit !important;
    }
    &.btn-success {
        min-width: inherit !important;
    }
    &.btn-danger {
        min-width: inherit !important;
    }
    &.btn-warning {
        min-width: inherit !important;
    }
    &.btn-outline-secondary {
        min-width: inherit !important;
    }
    &.btn-outline-primary {
        min-width: inherit !important;
    }
    &.btn-outline-success {
        min-width: inherit !important;
    }
    &.btn-outline-danger {
        min-width: inherit !important;
    }
    &.btn-outline-warning {
        min-width: inherit !important;
    }
}




.btn-group-vertical {
    > .btn-acceo.btn:last-child:not(:first-child) {
        border-bottom-right-radius: $ac-button-border-radius;
        border-bottom-left-radius: $ac-button-border-radius;
    }

    > .btn-acceo.btn:first-child:not(:last-child) {
        border-top-right-radius: $ac-button-border-radius;
        border-top-left-radius: $ac-button-border-radius;
    }
}

.error-stack-trace {
    font-size: xx-small;
}

.btn.btn-acceo.btn-sm {
    min-height: inherit;
    font-size: 14px;
}

.btn.btn-acceo.btn-xs {
    min-height: inherit;
    font-size: 14px;
    padding: 0.15rem 0.5rem;
}

.modal-dialog {
    &.fixed-modal {
        height:95%;
        margin-bottom: 0px;
        @media only screen and (max-device-width: $screen-xxs-top) {
            height:97%;
        }
    }
}

.modal-content {
    &.fixed-modal {
        height:98%;
    }
}

body[data-os=iPad].modal-open, body[data-os=iPhone].modal-open  {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

body.print-modal-only  {
    font-size: 10pt;

    overflow: visible !important;
    > * {
        visibility: hidden;
    }

    a2000-workspace {
        display: none;
    }

    modal-overlay {
        position: inherit !important;
    }

    .modal {
        position: inherit !important;
        overflow: visible !important;
    }

    .modal-dialog {
        overflow: visible !important;
    }

    .modal-header * {
        visibility: visible !important;
    }

    .modal-body * {
        visibility: visible !important;
    }

    .modal-body {
        overflow: visible !important;
    }

    .modal-dialog {
        width: 100%;
        margin: 0;

        &.fixed-modal {
            height:inherit;
        }
    }

    .modal-content {
        &.fixed-modal {
            height: inherit;
        }
    }
}

a {
    cursor: pointer;
    text-decoration: none;
}

a:hover, a:focus {
    color: $ac-link-color;
    text-decoration: underline;
  }

a:not(.btn):visited {
    color: $ac-link-color;
}

/*Date picker container*/ bs-datepicker-container { z-index: 3000; }
.theme-acceo {
    .bs-datepicker-head {
        background-color: $ac-color-primary-blue;
    }

    .btn-today-wrapper .btn-success {
        background-color: $ac-color-primary-blue;
        border-color: $ac-color-primary-blue;
        color: white;
    }

    .btn-clear-wrapper .btn-success {
        background-color: $ac-color-primary-blue;
        border-color: $ac-color-primary-blue;
        color: #fff;
    }

    .bs-datepicker-predefined-btns > button {
        background-color: $ac-color-primary-blue;
        border-color: $ac-color-primary-blue;
        color: #fff;
        text-align: center;
    }

    .bs-datepicker-body {
        table {
            td {
                span.selected,
                &.selected span,
                span[class*="select-"]:after,
                &[class*="select-"] span:after {
                    background-color: $ac-color-primary-blue;
                }

                &.week span {
                    color: $ac-color-primary-blue;
                }
            }
        }
    }
}

/* card images are added to card number */
.card-number-input {
    background-image: none;

    background-origin: content-box;
    background-position: 100% center;
    background-repeat: no-repeat;
    background-size: contain;
}

.credit-card-input {
    min-height: 38px;
    max-height: 38px;
}

.bambora-is-focused {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

pagination .pagination {
    margin-left: 5px;
}

input::-ms-clear {
    display: none;
}

.tooltip {
    z-index: 1503;
}

.fa-stack-2x {
    font-size: 1em;
}

.tr {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tr-2x {
    font-size: 2em;
}

td.separator_1 {
    padding-left: 60px;
}
td.separator_2 {
    padding-left: 150px;
}
td.separator_3 {
    padding-left: 20px;
}
td.separator_4 {
    // fix to aling the table in the print screen
    padding-left: 150px;
}

.form-inline.quick-search .form-group {
    margin-right:4px;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}

form-checkbox-bootstrap input[type="radio"], form-checkbox-bootstrap input[type="checkbox"] {
    margin-right:4px;
}

button:not(.list-group-item) + button:not(.list-group-item) {
    margin-left: 5px;
}

.display-none {
    display: none !important;
}

/* form-textbox-bootstrap + form-textbox-bootstrap > .form-group,
form-textbox-bootstrap + form-dropdown-bootstrap > .form-group,
form-dropdown-bootstrap + form-textbox-bootstrap > .form-group,
form-dropdown-bootstrap + form-dropdown-bootstrap > .form-group,
form-dropdown-bootstrap + form-dropdown-bootstrap > .form-group,
form-formgroup-bootstrap + form-textbox-bootstrap > .form-group,
form-formgroup-bootstrap + form-textbox-bootstrap > .form-group,
form-textbox-bootstrap + .form-group {
    margin-top: 0.5rem;
} */



.quick-search {
    .form-group.row {
        margin-top: 0rem;
    }

    .form-group.row.form-text-addon {
        padding: 0;
    }
}


.form-group.row:not(.form-text-addon) {
    margin-top: 0.5rem;
}

.form-group.row.form-text-addon {
    margin-top: 0rem !important;
}

.form-group.form-text-addon.row>* {
    width: auto;
}

.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;
    text-decoration: none;
}

.summary-column {
    font-size: small;
    z-index: 1;
    border-right: 1px solid #ddd;
}

.btn-grid-option {
    font-size: small;
    padding-right: 0;
    display: inline-block;
}

.detail-column > .row.justify-content-end:first-child {
    margin-bottom: 15px;
}

.navbar {
    min-height: 45px;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropdown-menu {
    li {
        a:visited {
            color: #3A3C3D;
        }
    }
    li {
        a.disabled {
            color: #9aa0a1;
        }
    }
}

a:not([href]):not([class]) {
    color: $ac-link-color;
}

a:not([href]):not([class]):hover {
    color: $ac-link-color;
    text-decoration: underline;
}

.text-info {
    color: #31708f !important;
}


.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
    color: #a94442;
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}
