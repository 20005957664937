$header-height: 68px;
$sub-navbar-height: 40px;
$footer-height: 50px;
$footer-height-mob: 57px;
$screen-xxs-bottom: 0px;
$screen-xxs-top: 400px;
$screen-xs-bottom: 401px;
$screen-xs-top: 767px;
$screen-sm-bottom: 768px;
$screen-sm-top: 991px;
$screen-md-bottom: 992px;
$screen-md-top: 1199px;
$screen-lg-bottom: 1200px;

// Colors
$ac-color-primary-blue: #1E95D3;
$ac-color-primary-dark-grey: #3A3C3D;
$ac-color-primary-light-grey: #B6B7B6;

$ac-color-secondary-dark-blue: #003F72;
$ac-color-secondary-light-blue: #0CC6DE;
$ac-color-secondary-light-green: #8FAD15;
$ac-color-secondary-dark-green: #206C49;
$ac-color-secondary-dark-purple: #412D5D;
$ac-color-secondary-light-purple: #A30050;
$ac-color-secondary-orange: #E35205;
$ac-color-secondary-beige: #C7B37F;
$ac-color-secondary-red: #a94442;

// Text colors
$ac-text-color-blue: #2695ce;
$ac-text-color-dark-grey: #404245;
$ac-text-color-light-grey: #b6b9b9;

// Fonts
$ac-font-family-sans-serif: "Open Sans","Helvetica Neue",sans-serif;
$ac-font-base: 16px;

// Header
$ac-header-vertical-margin: 20px;
$ac-header-horizontal-margin: 15px;
$ac-header-max-height: 90px;
$ac-header-font-size: 14px;

// Navbar
$ac-navbar-font-size: 18px;

// Buttons
$ac-button-border-radius: 20px;
$ac-button-font-weight: bold;
$ac-button-font-size: 16px;
$ac-button-height: 40px;
$ac-button-text-transform: none;
$ac-button-vertical-padding: 12px;
$ac-button-min-width: 200px;

// Header buttons
$ac-button-header-font-size: 18px;
$ac-button-header-text-transform: uppercase;
$ac-button-header-min-width: initial;

// Links
$ac-link-color: $ac-text-color-blue;
$ac-link-color-visited: $ac-color-secondary-dark-blue;


$header-height: 68px;
$sub-navbar-height: 40px;
$footer-height: 50px;
$footer-height-mob: 57px;
$screen-xxs-bottom: 0px;
$screen-xxs-top: 400px;
$screen-xs-bottom: 401px;
$screen-xs-top: 767px;
$screen-sm-bottom: 768px;
$screen-sm-top: 991px;
$screen-md-bottom: 992px;
$screen-md-top: 1199px;
$screen-lg-bottom: 1200px;

$input-focus-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
