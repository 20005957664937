.acceo-header {
    max-height: $ac-header-max-height;
    margin-top: $ac-header-vertical-margin;
    margin-bottom: $ac-header-vertical-margin;
    font-size: $ac-header-font-size;

    .btn {
        &.btn-acceo {
            font-size: $ac-button-header-font-size;
            text-transform: $ac-button-header-text-transform;
            min-width: $ac-button-header-min-width;
        }
    }
}