@import "U2000_style-variables";

// ----------------------------------------------------------------------------
$primary: $ac-color-primary-blue;
$secondary: $ac-color-primary-dark-grey;
$success: $ac-color-secondary-light-green;
$info: $ac-color-secondary-light-blue;
$warning: $ac-color-secondary-orange;
$danger: $ac-color-secondary-red;

$body-color: $ac-color-primary-dark-grey;
$font-family-sans-serif: $ac-font-family-sans-serif;


// Configuration
@import "../../../node_modules/bootstrap/scss/functions";

$input-focus-width: 1px;

@import "../../../node_modules/bootstrap/scss/_variables";

// @import "../../../node_modules/bootstrap/scss/_maps";
@import "../../../node_modules/bootstrap/scss/_mixins";
@import "../../../node_modules/bootstrap/scss/_root";

@import "../../../node_modules/bootstrap/scss/_utilities";

@import "../../../node_modules/bootstrap/scss/_reboot";
@import "../../../node_modules/bootstrap/scss/_type";
@import "../../../node_modules/bootstrap/scss/_images";
@import "../../../node_modules/bootstrap/scss/_containers";
@import "../../../node_modules/bootstrap/scss/_grid";
@import "../../../node_modules/bootstrap/scss/_tables";
@import "../../../node_modules/bootstrap/scss/_forms";
@import "../../../node_modules/bootstrap/scss/_buttons";
@import "U2000_buttons";
@import "../../../node_modules/bootstrap/scss/_transitions";
@import "../../../node_modules/bootstrap/scss/_dropdown";
@import "../../../node_modules/bootstrap/scss/_button-group";
@import "../../../node_modules/bootstrap/scss/_nav";
@import "../../../node_modules/bootstrap/scss/_navbar";
@import "../../../node_modules/bootstrap/scss/_card";
//@import "../../../node_modules/bootstrap/scss/_accordion";
@import "../../../node_modules/bootstrap/scss/_breadcrumb";
@import "../../../node_modules/bootstrap/scss/_pagination";
@import "../../../node_modules/bootstrap/scss/_badge";
@import "../../../node_modules/bootstrap/scss/_alert";
@import "../../../node_modules/bootstrap/scss/_progress";
@import "../../../node_modules/bootstrap/scss/_list-group";
@import "../../../node_modules/bootstrap/scss/_close";
@import "../../../node_modules/bootstrap/scss/_toasts";
@import "../../../node_modules/bootstrap/scss/_modal";
@import "../../../node_modules/bootstrap/scss/_tooltip";
@import "../../../node_modules/bootstrap/scss/_popover";
//@import "../../../node_modules/bootstrap/scss/_carousel";
@import "../../../node_modules/bootstrap/scss/_spinners";
@import "../../../node_modules/bootstrap/scss/_offcanvas";

@import "../../../node_modules/bootstrap/scss/_helpers";
@import "../../../node_modules/bootstrap/scss/utilities/_api";

@import "U2000_style-mixins";
@import "U2000_base";
@import "U2000_header";
@import "U2000_link";
@import "U2000_navbar";
@import "U2000_helpers";
@import "U2000_bootstrap4shim";

@import "../U2000_shared/U2000_directives/U2000_busyindicator-overlay/U2000_busyindicator-overlay.directive";
@import "../../gamma/form/formquestion-required/formquestion-required.directive";

html {
    position: relative;
    min-height: 100%;
}
body {
    background-color: #f6f6f6;
}
