.text-white {
    color: #fff;
}

.text-success {
    color: $ac-color-secondary-light-green;
}

.text-normal {
    color: $ac-color-primary-dark-grey;
}

.text-disabled {
    color: $ac-color-primary-light-grey;
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.sm-margin-bottom {
    margin-bottom: 5px;
}

.md-margin-bottom {
    margin-bottom: 10px;
}

.lg-margin-bottom {
    margin-bottom: 15px;
}

.sm-margin-top {
    margin-top: 5px;
}

.md-margin-top {
    margin-top: 10px;
}

.lg-margin-top {
    margin-top: 15px;
}

.sm-margin-right {
    margin-right: 5px;
}

.md-margin-right {
    margin-right: 10px;
}

.lg-margin-right {
    margin-right: 15px;
}

.sm-margin-left {
    margin-left: 5px;
}

.md-margin-left {
    margin-left: 10px;
}

.lg-margin-left {
    margin-left: 15px;
}

.sm-indent {
    margin-left: 5px;
}

.md-indent {
    margin-left: 10px;
}

.lg-indent {
    margin-left: 15px;
}

.xs-padding-left {
    padding-left: 2px;
}

.xs-padding-right {
    padding-right: 2px;
}

.label-as-badge {
    border-radius: 1em;
    padding-top: 0.3em;
    &.bg-primary {
        color: $ac-color-primary-dark-grey;
        background-color: lighten($ac-color-primary-blue, 47%);
    }
    &.bg-success {
        color: $ac-color-primary-dark-grey;
        background-color: lighten($ac-color-secondary-light-green, 45%);
    }
    &.bg-info {
        color: $ac-color-primary-dark-grey;
        background-color: lighten($ac-color-secondary-light-blue, 45%);
    }
    &.bg-warning {
        color: $ac-color-primary-dark-grey;
        background-color: lighten($ac-color-secondary-orange, 45%);
    }
    &.bg-danger {
        color: $ac-color-primary-dark-grey;
        background-color: lighten($ac-color-secondary-red, 35%);
    }
}

.icon-col {
    width: 39px;
    text-align: center;
}

.date-col {
    width: 105px;
}

.medium-text-col {
    width: 160px;
}

.amount-col {
    width: 121px;
    text-align: right;
}

.number-col {
    text-align: right;
}


.form-condensed {
    .form-group:not(.form-text-addon) {
        margin-top: 2px;
        margin-bottom: 5px;
    }
}

.note {
    font-size: small;
    font-style: italic;
}

.negative-amount {
    color: red;
}

.list-group {
    &.list-group-striped:not(.list-group-hover) {
        &>li:nth-of-type(odd),
        &>button:nth-of-type(odd) {
            background-color: #f9f9f9;
        }
    }
    &.list-group-hover {
        &.list-group-striped {
            &>li:nth-of-type(odd):not(.active):not(:hover),
            &>button:nth-of-type(odd):not(.active):not(:hover) {
                background-color: #f9f9f9;
            }
        }
        >.list-group-item:hover {
            cursor: pointer;
        }
        >.list-group-item:not(.active):hover {
            background-color: #f5f5f5;
            color: $ac-color-primary-dark-grey;
        }
    }
    >.list-group-item {
        &.active {
            background-color: lighten($ac-color-primary-blue, 47%);
            color: $ac-color-primary-dark-grey;
        }
    }
}

// Class defined for IE that needs displayed submit button to enable the Enter key press.
.hidden-submit-button {
    width: 0px;
    height: 0px;
    position: absolute;
    top: -99999px;
    left: -99999px;
}

.pull-container {
    overflow: hidden;
}

.collapsible {
    overflow: hidden;
}

input {
    &.break-browser-auto-fill {
        position: absolute;
        width: 0;
        height: 0;
        border: 0;
    }
}

.form-group-title {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 15px;
}

.display-inline-block {
    display: inline-block;
}

.display-inline {
    display: inline;
}

.not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.pagination-xs {
    > li > a, > li > span {
        padding: 5px 6px;
        font-size: 13px;
        line-height: 1.5;
    }
}

.text-separator {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAADCAYAAABS3WWCAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEQ5RDgxQzc2RjQ5MTFFMjhEMUNENzFGRUMwRjhBRTciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEQ5RDgxQzg2RjQ5MTFFMjhEMUNENzFGRUMwRjhBRTciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0RDlEODFDNTZGNDkxMUUyOEQxQ0Q3MUZFQzBGOEFFNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0RDlEODFDNjZGNDkxMUUyOEQxQ0Q3MUZFQzBGOEFFNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvXFWFAAAAAYSURBVHjaYvj//z8D0/Pnz/8zgFgAAQYAS5UJscReGMIAAAAASUVORK5CYII=) repeat-x scroll 0 10px;
    span {
        background-color: white;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.display-none {
    display: none;
}


