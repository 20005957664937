// Built within the global U2000_style.scss

.required-marker {
    z-index: 10;
    position: absolute;
    font-size: 6px;
    left: 8px;
    top: 12px;
    width: 10px;
    &::before {
        font-family: 'Font Awesome 5 Free';
        font-size: 6px;
        font-weight: 900;
        content: "\f111";
        color: $ac-color-primary-blue;
    }
}

.form-group.required:not(.hide-required-marker) {
    position: relative;

    &.required-no-label {
        &.required-sm {
            .required-marker {
                top: 13px;
            }
        }

        input {
            padding-left: 19px;
        }
        select {
            padding-left: 15px;
        }
    }

    &:not(.required-no-label) {
        .required-marker {
            top: 8px;
            left: 2px;

            ~label,~.radio-label {
                padding-left: 25px;
            }
        }
    }

}

.form-group.required.hide-required-marker {
    .required-marker {
        display: none;
    }
}

.form-horizontal {
    .form-group.required:not(.hide-required-marker) {
        &:not(.required-no-label) {
            .required-marker {
                left: 2px;
                @media (min-width: $screen-xs-top) {
                    top: 14px;
                }

                ~label,~.radio-label {
                    padding-left: 25px;
                }
            }
        }
    }
}

.form-inline {
    .form-group.required:not(.hide-required-marker) {
        &:not(.required-no-label) {
            .required-marker {
                top: 8px;
            }
        }
    }
}

.has-error .required-marker::before {
    color: #b94a48;
}



.input-group>.required-marker {
    z-index: 3;
}

