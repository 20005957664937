 @mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $size $color;
        -moz-box-shadow:inset $top $left $blur $size $color;
        box-shadow:inset $top $left $blur $size $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $size $color;
        -moz-box-shadow: $top $left $blur $size $color;
        box-shadow: $top $left $blur $size $color;
    }
}

@mixin text-field {
 display: inline-block;
  outline: none;
  text-decoration: none;
  font: 14px/100% Arial, Helvetica, sans-serif;
  padding: .5em;
  text-shadow: 0 1px 1px rgba(0,0,0,.3);
  @include rounded();
  @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
}

@mixin button($color: $red, $text_color: $white) {
  display: inline-block;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font: 14px/100% Arial, Helvetica, sans-serif;
  padding: .5em 2.0em .55em;
  text-shadow: 0 1px 1px rgba(0,0,0,.3);
  @include rounded();
  @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));

  color: $text_color !important;
  font-weight: bold;
  border: solid 1px darken($color, 18%);
  background: $color;
  @include gradient(saturate($color, 15%), darken($color, 15%));

  &:hover {
    text-decoration: none;
    background: saturate($color, 10%);
    @include gradient(saturate($color, 5%), darken($color, 5%));
  }

  &:active {
    position: relative;
    top: 1px;
    color: saturate($color, 15%);
    @include gradient(saturate($color, 15%), lighten($color, 15%));
  }
}

@mixin rounded($radius: 0.5em) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin rotate($degree) {
    -ms-transform: rotate($degree); /* IE 9 */
    -webkit-transform: rotate($degree); /* Chrome, Safari, Opera */
    transform: rotate($degree);
}

@mixin gradient($from, $to) {
  background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background: -moz-linear-gradient(top,  $from, $to);
  filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}

@mixin U2000MX_WholePage() {
    .container:first-child {
        margin-top: 20px;
    }
    .container-main:first-child {
        margin-top: 20px;
    }
}

@mixin U2000MX_WholeMobilePage() {
    .container:first-child {
        overflow: hidden;
        margin-top: 3px;
        padding-left: 3px;
        padding-right: 3px;
    }
}

@mixin U2000MX_Panels() {
    .card {
        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
        }

        table:not(.table-hover) {
            width: 100%;
            &.table {
                margin-bottom: 0;
                > tbody > tr:first-of-type > td {
                    border-top-width: 0;
                }
            }
        }
    }
}

@mixin U2000MX_TableFooter() {
    tfoot {
        @include U2000MX_Navigation();

        th {
            padding-bottom: 0;
        }
    }
}

@mixin U2000MX_Navigation() {
    .navigation {
        text-align: right;

        button {
            color: #000;
            padding-top: 0;
            padding-bottom: 0;

            &:last-child {
                padding-right: 0;
            }
        }

    }
}

@mixin U2000MX_TableDetail() {
    .table-detail {
        background-color: #fff;
        margin-bottom: 0;

        .well {
            margin-bottom: 0;
            margin-left: 20px;
        }

        @include U2000MX_TableFooter();

        .detail-row {
            background-color: #fff;
            td {
                padding: 2px;
            }
        }
    }
}

@mixin U2000MX_DisabledResponsiveNavbar {
    // Deactivate Responsiveness
    @media (max-width: $screen-xs-top) {
        .navbar-header {
            float: left;
        }

        .navbar-nav {
            margin: 0;
            //margin-right: -15px;
            float: left;

            &.navbar-right {
                float: right !important;

                .dropdown-menu {
                    right: 0;
                    left: auto;
                }
            }

            .open .dropdown-menu {
                position: absolute;

                > li > a {
                    line-height: inherit;
                }

                > li > a,
                .dropdown-header {
                    padding: 3px 20px 3px 20px;
                }
            }

            > li {
                float: left;

                > a {
                    padding-top: 17px;
                    padding-bottom: 17px;
                }
            }
        }
    }
}

