// Built within the global U2000_style.scss

busyindicator-overlay {
    .overlay {
        background-color: #000;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.3;
        top: 0;
        left: 0;
        padding: 0;
        z-index: 10;
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .busyindicator {
        position: absolute;
        z-index: 1041;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -15px;
        border: 8px solid #f3f3f3;
        border-radius: 50%;
        border-top: 8px solid $ac-color-primary-blue;
        -webkit-animation: spin 2s linear infinite; /* Safari and Chrome */
        animation: spin 2s linear infinite;
    }
}
