// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

// scss-docs-start btn-variant-mixin
@mixin acceo-button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
  $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
  $hover-color: color-contrast($hover-background),
  $active-background: if($color == $color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
  $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
  $active-color: color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: color-contrast($disabled-background)
) {
  color: white; //$color;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  &.btn-acceo {
    @include btn-acceo-style();
    @include btn-acceo-font();
  }

  &.btn-acceo-style {
    @include btn-acceo-style();
  }

  &:hover {
    color: white; //$hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  .btn-check:focus + &,
  &:focus {
    color: white; //$hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: white; //$active-color;
    background-color: $active-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: white; //$disabled-color;
    background-color: $disabled-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $disabled-border;
  }
}
// scss-docs-end btn-variant-mixin

// scss-docs-start btn-outline-variant-mixin
@mixin acceo-button-outline-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  color: $color;
  border-color: $color;

  &.btn-acceo {
    @include btn-acceo-style();
    @include btn-acceo-font();
  }

  &.btn-acceo-style {
    @include btn-acceo-style();
  }

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  .btn-check:focus + &,
  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}
// scss-docs-end btn-outline-variant-mixin

@mixin btn-acceo-style() {
    min-height: $ac-button-height;
    padding-left: $ac-button-vertical-padding;
    padding-right: $ac-button-vertical-padding;
    text-transform: $ac-button-text-transform;
    border-radius: $ac-button-border-radius;

    min-width: $ac-button-min-width;
}

@mixin btn-acceo-font() {
    font-size: $ac-button-font-size;
    font-weight: $ac-button-font-weight;
}

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  @include transition($btn-transition);

  &:hover {
    color: $body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  .btn-check:focus + &,
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }
}


//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
    .btn-acceo.btn-#{$color} {
        @include acceo-button-variant($value, $value);
    }
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
    .btn-acceo.btn-outline-#{$color} {
        @include acceo-button-outline-variant($value);
    }
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}
// scss-docs-end btn-variant-loops


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $btn-link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $btn-link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

//
// Button default
//
.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }

  .btn-default:focus, .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
  }

  .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus,
  .open > .btn-default.dropdown-toggle:hover,
  .open > .btn-default.dropdown-toggle:focus,
  .open > .btn-default.dropdown-toggle.focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }

  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    background-image: none;
  }

  .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus,
  fieldset[disabled] .btn-default:hover,
  fieldset[disabled] .btn-default:focus,
  fieldset[disabled] .btn-default.focus {
    background-color: #fff;
    border-color: #ccc;
  }

  .btn-default .badge {
    color: #fff;
    background-color: #333;
  }

  .btn-default.btn-acceo {
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none;
    border-radius: 20px;
    min-width: 200px;
    font-size: 16px;
    font-weight: bold;
  }

  .btn-default.btn-acceo-style {
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none;
    border-radius: 20px;
    min-width: 200px;
  }

  .btn-default.btn-acceo-adjust {
    min-width: initial;
    padding-right: 12px;
    padding-left: 12px;
  }

  .btn-default.btn-acceo-font {
    font-size: 16px;
    font-weight: bold;
  }

  .btn-default.inverse {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #fff;
    color: #fff;
  }

  .btn-default.inverse:hover, .btn-default.inverse:active, .btn-default.inverse:focus {
    background: #fff none repeat scroll 0 0;
    color: #fff;
  }

  .btn-default.inverse:active:hover, .btn-default.inverse .active:hover, .btn-default.inverse:active:focus, .btn-default.inverse .active:focus, .btn-default.inverse:active.focus, .btn-default.inverse .active.focus {
    border-color: #fff;
  }

  .btn-default.inverse.white {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #fff;
    color: #fff;
  }

  .btn-default.inverse.white:hover, .btn-default.inverse.white:active, .btn-default.inverse.white:focus {
    background: #fff none repeat scroll 0 0;
    border: 2px solid #fff;
    color: #fff;
  }

  .btn-default.white {
    background: #fff none repeat scroll 0 0;
    border: 2px solid #fff;
  }

  .btn-default.white:active:hover, .btn-default.white .active:hover, .btn-default.white:active:focus, .btn-default.white .active:focus, .btn-default.white:active.focus, .btn-default.white .active.focus, .btn-default.white.disabled:hover, .btn-default.white.disabled:focus, .btn-default.white.disabled.focus, .btn-default.white[disabled]:hover, .btn-default.white[disabled]:focus, .btn-default.white[disabled].focus {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
  }

  .btn-default.grey {
    background: #3A3C3D none repeat scroll 0 0;
    border: 2px solid #3A3C3D;
    color: #fff;
  }

  .btn-default.grey:hover, .btn-default.grey:focus, .btn-default.grey:active, .btn-default.grey:active:hover, .btn-default.grey .active:hover, .btn-default.grey:active:focus, .btn-default.grey .active:focus, .btn-default.grey:active.focus, .btn-default.grey .active.focus, .btn-default.grey.disabled:hover, .btn-default.grey.disabled:focus, .btn-default.grey.disabled.focus, .btn-default.grey[disabled]:hover, .btn-default.grey[disabled]:focus, .btn-default.grey[disabled].focus {
    color: #eee;
    background-color: #3A3C3D;
    border-color: #3A3C3D;
  }

  .btn-default.white:hover, .btn-default.grey:hover, .btn-default.white:active, .btn-default.grey:active, .btn-default.white:focus, .btn-default.grey:focus {
    opacity: 0.9;
  }

  .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus {
    opacity: 0.65;
  }

  .btn-default.white {
    color: #fff;
  }

